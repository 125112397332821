import React from "react";
import "./Home.style.css";
import mariageImage from "../../assets/HomePage/mariage.jpg";
import anniversaireImage from "../../assets/HomePage/anniversaire.jpg";
import baptemeImage from "../../assets/HomePage/bapteme.jpg";

const Home = () => {
  return (
    <div className="home-section">
      <div className="image-container">
        <a href="/portfolio/Anniversaires">
          <div
            className="home-image"
            style={{
              backgroundImage: `url(${"https://res.cloudinary.com/dtqkow7ol/image/upload/v1737221393/DSC_0627_p7lfe2.jpg"})`,
            }}
          ></div>
          <div className="image-overlay"></div> {/* Overlay sombre */}
          <span className="image-label">Anniversaires</span>
          <div className="portfolio-link">Voir le Portfolio</div>
          {/* Nouvelle ligne */}
        </a>
      </div>
      <div className="image-container">
        <a href="/portfolio/Mariages">
          <div
            className="home-image"
            style={{
              backgroundImage: `url(
                "https://res.cloudinary.com/dtqkow7ol/image/upload/v1737221515/DSC_1008_ssoryt.jpg"
              )`,
            }}
          ></div>
          <div className="image-overlay"></div> {/* Overlay sombre */}
          <span className="image-label">Mariages</span>
          <div className="portfolio-link">Voir le Portfolio</div>
          {/* Nouvelle ligne */}
        </a>
      </div>
      <div className="image-container">
        <a href="/portfolio/Baptemes">
          <div
            className="home-image"
            style={{
              backgroundImage: `url(${"https://res.cloudinary.com/dtqkow7ol/image/upload/v1737221433/DSC_1143_xqxcuq.jpg"})`,
            }}
          ></div>
          <div className="image-overlay"></div> {/* Overlay sombre */}
          <span className="image-label">Baptêmes</span>
          <div className="portfolio-link">Voir le Portfolio</div>
          {/* Nouvelle ligne */}
        </a>
      </div>
    </div>
  );
};

export default Home;
