import React from "react";
import { motion } from "framer-motion";
import "./Prestations.style.css";

const Prestations = () => {
 const tarifs = [
   {
     category: "Mariage",
     items: [
       { description: "Mairie", price: "180 €" },
       { description: "Église", price: "220 €" },
       { description: "Mairie & Église", price: "370 €" },
       { description: "Mairie & 1h vin d’honneur", price: "370 €" },
       { description: "Église & 1h vin d’honneur", price: "410 €" },
       {
         description: "Mairie & Église & 1h vin d’honneur",
         price: "540 €",
       },
       {
         description:
           "+ Photos de groupe/couple dans un lieu supplémentaire Jour J",
         price: "+ 150 €",
       },
       {
         description: "+ Préparation de la mariée (45min)",
         price: "+ 150 €",
       },
       {
         description: "+ 1h vin d’honneur",
         price: "+ 150 €",
       },
       { description: "Mairie & 2h vin d’honneur", price: "470 €" },
       { description: "Église & 2h vin d’honneur", price: "510 €" },
       {
         description: "Mairie & Église & 2h vin d’honneur",
         price: "640 €",
       },
       {
         description: "+ Photos de groupe/couple au studio un autre jour (45min)",
         price: "+ 100 €",
       },
     ],
   },
   {
     category: "Shooting Professionnel",
     items: [
       { description: "45min", price: "170 €" },
       { description: "1h30", price: "300 €" },
       { description: "2h30", price: "440 €" },
     ],
   },
   {
     category: "Anniversaire",
     items: [
       { description: "1h", price: "190 €" },
       { description: "2h", price: "360 €" },
       { description: "3h", price: "550 €" },
       { description: "4h", price: "720 €" },
     ],
   },
   {
     category: "Baptême",
     items: [
       { description: "Messe", price: "220 €" },
       { description: "Messe & 1h réception", price: "400 €" },
       { description: "Messe & 2h réception", price: "560 €" },
       { description: "Messe & 3h réception", price: "730 €" },
       { description: "+ Mairie", price: "+ 150 €" },
     ],
   },
   {
     category: "EVJF",
     items: [
       { description: "1h", price: "190 €" },
       { description: "2h", price: "360 €" },
       { description: "3h", price: "550 €" },
       { description: "4h", price: "720 €" },
     ],
   },
   {
     category: "Shooting Animalier",
     items: [
       { description: "45min", price: "170 €" },
       { description: "1h30", price: "300 €" },
       { description: "2h30", price: "440 €" },
     ],
   },
 ];

  return (
    <div className="container">
      <div>
        <h1>Tableaux des tarifs</h1>
        {tarifs.map((section, index) => (
          <div key={index} className="tarif-section">
            <h2>{section.category}</h2>
            <table>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Prix</th>
                </tr>
              </thead>
              <tbody>
                {section.items.map((item, itemIndex) => (
                  <tr key={itemIndex}>
                    <td>{item.description}</td>
                    <td>{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <div className="info-section">
        <h1>Comment je fonctionne ?</h1>
        <p>
          <strong>Premier contact :</strong> Nous planifions un rendez-vous
          téléphonique pour discuter en détail de votre mariage et de vos
          attentes.
        </p>
        <p>
          <strong>Jour J :</strong> Je serai présent pour capturer chaque
          instant précieux, sans aucune limite sur le nombre de photos prises.
        </p>
        <p>
          <strong>Post-production :</strong> Chaque photo sera minutieusement
          retouchée, avec des versions en noir et blanc pour certaines d'entre
          elles.
        </p>
        <p>
          <strong>Livraison des photos :</strong> Vous recevrez vos photos sur
          une clé USB offerte. Si vous habitez dans mon secteur, je vous la
          livrerai personnellement. Lors de cette remise, nous organiserons
          également une séance de visionnage de 30 minutes pour parcourir les
          photos ensemble.
        </p>
        <p>
          <strong>Déplacement inclus :</strong> Mon déplacement le jour de
          l’événement et, le cas échéant, lors de la remise des photos est
          inclus dans le tarif.
        </p>
        <p>
          <strong>Réservation :</strong> Pour réserver votre date, un acompte de
          40€ est demandé via PayPal. Une fois l'acompte reçu, vous recevrez une
          confirmation écrite de la prestation choisie et de la date.
        </p>
        <p>
          <strong>Paiement :</strong> Le jour du mariage, la moitié du montant
          total sera à régler en espèces, et le solde sera à verser lors de la
          remise des photos.
        </p>
      </div>
    </div>
  );
};

export default Prestations;
